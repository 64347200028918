class StoreLink {
  constructor (
    id = undefined,
    generatedBy,
    originalUrl,
    openInPdfViewer,
    totalNrOfClicks,
    nrOfUniqueClicks,
    excludedClickerIds,
    additionalInfo,
    createdAt,
    intendedForClientId,
    categoryId,
    generatedBySalesAgentId,
    manuallyCreated,
    descriptionLink
  ) {
    this.id = id
    this.generatedBy = generatedBy
    this.generatedByUser = null
    this.originalUrl = originalUrl
    this.openInPdfViewer = openInPdfViewer
    this.totalNrOfClicks = totalNrOfClicks
    this.nrOfUniqueClicks = nrOfUniqueClicks
    this.excludedClickerIds = excludedClickerIds
    this.additionalInfo = additionalInfo
    this.createdAt = new Date(createdAt)
    this.intendedForClientId = intendedForClientId
    this.intendedForClient = null
    this.categoryId = categoryId
    this.category = null
    this.generatedBySalesAgentId = generatedBySalesAgentId
    this.generatedBySalesAgent = null
    this.manuallyCreated = manuallyCreated
    this.descriptionLink = descriptionLink
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // generatedBy is read-only
      originalUrl: this.originalUrl,
      openInPdfViewer: this.openInPdfViewer,
      // totalNrOfClicks is read-only
      // nrOfUniqueClicks is read-only
      // excludedClickerIds: this.excludedClickerIds
      // additionalInfo is read-only
      // createdAt is read-only
      // intendedForClientId is read-only
      // categoryId is read-only
      // generatedBySalesAgentId is read-only
      // manuallyCreated is read-only
      descriptionLink: this.descriptionLink
    })
  }

  static FromNormalizedRESTModel ({
    id,
    generatedBy,
    originalUrl,
    openInPdfViewer,
    totalNrOfClicks,
    nrOfUniqueClicks,
    excludedClickerIds,
    additionalInfo,
    createdAt,
    intendedForClientId,
    categoryId,
    generatedBySalesAgentId,
    manuallyCreated,
    descriptionLink
  }) {
    return new StoreLink(
      id,
      generatedBy,
      originalUrl,
      openInPdfViewer,
      totalNrOfClicks,
      nrOfUniqueClicks,
      excludedClickerIds,
      typeof additionalInfo === 'string'
        ? JSON.parse(additionalInfo)
        : additionalInfo,
      createdAt,
      intendedForClientId,
      categoryId,
      generatedBySalesAgentId,
      manuallyCreated,
      descriptionLink
    )
  }

  toJSON () {
    return {
      id: this.id,
      generatedBy: this.generatedBy,
      originalUrl: this.originalUrl,
      openInPdfViewer: this.openInPdfViewer,
      totalNrOfClicks: this.totalNrOfClicks,
      nrOfUniqueClicks: this.nrOfUniqueClicks,
      excludedClickerIds: this.excludedClickerIds,
      additionalInfo: this.additionalInfo,
      createdAt: this.createdAt,
      intendedForClientId: this.intendedForClientId,
      categoryId: this.categoryId,
      generatedBySalesAgentId: this.generatedBySalesAgentId,
      manuallyCreated: this.manuallyCreated,
      descriptionLink: this.descriptionLink
    }
  }

  static FromJSON ({
    id,
    generatedBy,
    originalUrl,
    openInPdfViewer,
    totalNrOfClicks,
    nrOfUniqueClicks,
    excludedClickerIds,
    additionalInfo,
    createdAt,
    intendedForClientId,
    categoryId,
    generatedBySalesAgentId,
    manuallyCreated,
    descriptionLink
  }) {
    return new StoreLink(
      id,
      generatedBy,
      originalUrl,
      openInPdfViewer,
      totalNrOfClicks,
      nrOfUniqueClicks,
      excludedClickerIds,
      additionalInfo,
      createdAt,
      intendedForClientId,
      categoryId,
      generatedBySalesAgentId,
      manuallyCreated,
      descriptionLink
    )
  }
}

export default StoreLink
